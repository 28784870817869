import type { FC } from 'react';
import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { cssMap } from '@compiled/react';

import { LinkItem } from '@atlaskit/side-navigation';
import type { LinkItemProps } from '@atlaskit/side-navigation';
import Tooltip from '@atlaskit/tooltip';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { usePageSpaceKey } from '@confluence/page-context';
import { PremiumLozengeLoadable } from '@confluence/trial-components';
import { LoadableAfterPaint } from '@confluence/loadable';
import { SPACE_ANALYTICS } from '@confluence/named-routes';
import {
	useTrackPremiumFeatureUsage,
	PremiumFeatureUsage,
} from '@confluence/premium-onboarding/entry-points/useTrackPremiumFeatureUsage';
import { useRouteName } from '@confluence/route-manager';

const styles = cssMap({
	root: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&&': {
			padding: `0px ${token('space.050')} 0px ${token('space.100')}`,
			height: '36px',
			minHeight: '36px',
			borderRadius: '3px',
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
			fontSize: '14px',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			span: {
				columnGap: token('space.100'),
			},
			backgroundColor: 'transparent',
			'&:hover': {
				backgroundColor: token('color.background.neutral.subtle.hovered'),
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
			'&, &:visited, &:active, &:focus': {
				color: token('color.text.subtle'),
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
			textDecoration: 'none !important',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			'[data-item-elem-before=true]': {
				marginRight: '0px',
				height: '24px',
				width: '24px',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
				'img, span, svg': {
					height: '20px',
					width: '20px',
				},
			},
		},
	},
	isSelected: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&&': {
			backgroundColor: token('color.background.selected'),
			'&:hover': {
				backgroundColor: token('color.background.selected.hovered'),
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
			'&, &:visited, &:active, &:focus': {
				color: token('color.text.selected'),
			},
		},
	},
});

const listStyles = xcss({
	marginTop: 'space.0',
});

export const GraphBarIcon = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GraphBarIcon" */ './img/GraphBarIcon'))
			.GraphBarIcon,
});

const i18n = defineMessages({
	analyticsTooltip: {
		id: 'side-navigation.space-navigation.analytics.tooltip',
		defaultMessage: 'View analytics for this space',
		description: 'Tooltip message on hover of analytics space link',
	},
	analytics: {
		id: 'side-navigation.container.analytics',
		defaultMessage: 'Analytics',
		description: 'Analytics Menu Item',
	},
});

type AnalyticsNavigationProps = Pick<LinkItemProps, 'onClick'>;

export const AnalyticsNavigation: FC<AnalyticsNavigationProps> = ({ onClick }) => {
	const [spaceKey] = usePageSpaceKey();

	const isSelected = useRouteName({
		selector: (routeName: string | undefined) => routeName === SPACE_ANALYTICS.name,
	});

	const { markFeatureAsUsed } = useTrackPremiumFeatureUsage(
		PremiumFeatureUsage.ANALYTICS_VIEW_SPACE,
		/* skip */ !isSelected,
	);
	useEffect(markFeatureAsUsed, [markFeatureAsUsed]);

	return (
		<SpotlightTarget key="space-analytics" name="page-analytics-spotlight">
			<Tooltip position="top" content={<FormattedMessage {...i18n.analyticsTooltip} />}>
				{(tooltipProps) => (
					<Box as="li" xcss={listStyles}>
						<LinkItem
							// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
							css={[styles.root, isSelected && styles.isSelected]}
							key="analytics"
							iconBefore={<GraphBarIcon isSelected={isSelected} />}
							isSelected={isSelected}
							href={SPACE_ANALYTICS.toUrl({ spaceKey })}
							{...tooltipProps}
							onClick={onClick}
							testId="space-navigation-analytics-link"
						>
							<FormattedMessage {...i18n.analytics} />
							<PremiumLozengeLoadable />
						</LinkItem>
					</Box>
				)}
			</Tooltip>
		</SpotlightTarget>
	);
};
