import type { FC } from 'react';
import React, { memo } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled, cssMap } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { LinkItem } from '@atlaskit/side-navigation';
import Lozenge from '@atlaskit/lozenge';
import Tooltip from '@atlaskit/tooltip';
import SmartLinkListIcon from '@atlaskit/icon/core/smart-link-list';

import { usePageSpaceKey } from '@confluence/page-context';
import type { DATABASE_CUSTOM_OVERVIEW } from '@confluence/named-routes';

const styles = cssMap({
	root: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&&': {
			padding: `0px ${token('space.050')} 0px ${token('space.100')}`,
			height: '36px',
			minHeight: '36px',
			borderRadius: '3px',
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
			fontSize: '14px',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			span: {
				columnGap: token('space.100'),
			},
			backgroundColor: 'transparent',
			'&:hover': {
				backgroundColor: token('color.background.neutral.subtle.hovered'),
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
			'&, &:visited, &:active, &:focus': {
				color: token('color.text.subtle'),
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
			textDecoration: 'none !important',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			'[data-item-elem-before=true]': {
				marginRight: '0px',
				height: '24px',
				width: '24px',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
				'img, span, svg': {
					height: '18px',
					width: '18px',
				},
			},
		},
	},
	isSelected: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&&': {
			backgroundColor: token('color.background.selected'),
			'&:hover': {
				backgroundColor: token('color.background.selected.hovered'),
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
			'&, &:visited, &:active, &:focus': {
				color: token('color.text.selected'),
			},
		},
	},
});

const i18n = defineMessages({
	databases: {
		id: 'side-navigation.databases.word',
		defaultMessage: 'Databases',
		description: 'Label for the button which navigates the user to their Databases tab',
	},
	alpha: {
		id: 'side-navigation.databases.alpha-label',
		defaultMessage: 'Alpha',
		description: "Label next to the 'Databases' link, indicating that the feature is in alpha.",
	},
	tooltip: {
		id: 'side-navigation.databases.tooltip',
		defaultMessage: 'View all databases in this space',
		description: 'Tooltip message on hover of Databases space link',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomItemDiv = styled.div({
	position: 'relative',
	borderRadius: '3px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapper = styled.span({
	display: 'inline-block',
	marginLeft: token('space.100'),
});

type DatabasesNavigationProps = {
	isSelected: boolean;
	onClick?: () => void;
	databasesRoute: typeof DATABASE_CUSTOM_OVERVIEW;
};

const DatabasesNavigationComponent: FC<DatabasesNavigationProps> = ({
	isSelected,
	onClick,
	databasesRoute,
}) => {
	const intl = useIntl();
	const [spaceKey] = usePageSpaceKey();

	return (
		<CustomItemDiv>
			<Tooltip position="top" content={intl.formatMessage(i18n.tooltip)}>
				{(tooltipProps) => (
					<li>
						<LinkItem
							css={[styles.root, isSelected && styles.isSelected]}
							iconBefore={<SmartLinkListIcon label="" spacing="spacious" />}
							isSelected={isSelected}
							href={databasesRoute.toUrl({ spaceKey })}
							{...tooltipProps}
							onClick={onClick}
							testId="space-navigation-databases-link"
						>
							{intl.formatMessage(i18n.databases)}
							<LozengeWrapper>
								<Lozenge appearance="new">{intl.formatMessage(i18n.alpha)}</Lozenge>
							</LozengeWrapper>
						</LinkItem>
					</li>
				)}
			</Tooltip>
		</CustomItemDiv>
	);
};

export const DatabasesNavigation = memo(DatabasesNavigationComponent);
